<script setup lang="ts">
import type { Game } from "@/types";

const { t } = useT();
withDefaults(
	defineProps<{
		games: Game[];
		rows: number | null;
		skipGamePreview?: boolean;
		link?: boolean;
		withAnimation?: boolean;
	}>(),
	{
		rows: null,
		link: false,
		withAnimation: false
	}
);

const { data: appInit } = useAppInitData();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const prepareImgUrl = useImage();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;

const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });
</script>

<template>
	<transition-group
		:name="withAnimation ? 'list-complete' : ''"
		:class="['grid-cards', { [`grid-cards--${rows}`]: rows, 'with-link': link, 'list-complete': withAnimation }]"
		tag="div"
	>
		<MGame
			v-for="game in games"
			:key="game.id"
			:is-guest="!!appInit?.isGuest"
			:game="game"
			:is-only-for-app="!!game.isOnlyForApp"
			:is-favorite="!!game.isFavorite"
			:is-new="!!game.isNew"
			:image="`${baseImageUrl}${game.logo || game.img}`"
			:image-pattern="imagePattern"
			:game-id="game?.id || 0"
			:insideTitle="game.title || ''"
			:skip-game-preview="skipGamePreview"
			:skeleton="'skeleton' in game"
			class="list-complete-item"
			@toggle-favorite="handleToggleToFavoriteClick(game)"
		/>
		<NuxtLink v-if="rows === 3 && link" class="show-all" data-tid="slider-games-all" to="/issues/all-games">
			<AText
				:size="{ full: 24, md: 14 }"
				:modifiers="['bold', 'underline', 'uppercase', 'center']"
				class="text-tlalnepantla text-coro"
			>
				{{ t("games.link.name") }}
			</AText>
		</NuxtLink>
	</transition-group>
</template>

<style scoped lang="scss">
$games-in-row: 7;
$games-in-row-mob: 3;

.grid-cards {
	max-width: calc(100% - 32px);
	width: 1300px;
	margin: 0 auto;
	display: grid;
	grid-gap: 40px 16px;
	grid-template-columns: repeat($games-in-row, 1fr);

	@include media-breakpoint-down(lg) {
		grid-gap: 40px 16px;
	}

	@media (orientation: portrait) and (max-width: 580px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (orientation: landscape) and (max-width: 992px) {
		grid-gap: 40px 8px;
		grid-template-columns: repeat($games-in-row, 1fr);
	}

	&--1 {
		& > div {
			&:nth-child(n + #{$games-in-row + 1}) {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			& > div {
				&:nth-child(n + #{$games-in-row-mob + 1}) {
					display: none;
				}
			}
		}
	}

	&--2 {
		& > div {
			&:nth-child(n + #{$games-in-row * 2 + 1}) {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			& > div {
				&:nth-child(n + #{$games-in-row-mob * 2 + 1}) {
					display: none;
				}
			}
		}
	}

	&--3 {
		& > div {
			&:nth-child(n + #{$games-in-row * 3 + 1}) {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			& > div {
				&:nth-child(n + #{$games-in-row-mob * 3 + 1}) {
					display: none;
				}
			}
		}

		&.with-link {
			& > div {
				&:nth-child(n + #{$games-in-row * 3}) {
					display: none;
				}
			}

			@include media-breakpoint-down(sm) {
				& > div {
					&:nth-child(n + #{$games-in-row-mob * 3}) {
						display: none;
					}
				}
			}
		}
	}

	.show-all {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		border: 1px solid var(--cannes);
		background: var(--gosaba);
		box-shadow:
			0 0 6px 0 var(--cangzhou),
			0 4px 4px 0 rgba(255, 225, 180, 0.25) inset,
			0 0 18px 0 rgba(255, 152, 220, 0.76) inset;

		&:hover {
			cursor: pointer;

			span {
				text-decoration: none;
			}
		}
	}
}

.list-complete {
	.game-wrapper {
		transition: all 0.6s ease;
		display: inline-block;
	}
	.list-complete-enter-from,
	.list-complete-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}

	.list-complete-leave-active {
		position: absolute;
	}
}
</style>
<style lang="scss">
.icon-favorite {
	background-image: url(/assets/icons/20/favorite-notify.svg);
}

.Toastify__toast-container--bottom-center {
	@include media-breakpoint-up(lg) {
		left: auto;
		right: 16px;
		transform: translateX(0%);
	}
}
</style>
